import '../bootstrap';
window.fitvids = require('fitvids');
window.magnificPopup = require('magnific-popup');
import Alpine from 'alpinejs' 
window.Alpine = Alpine
queueMicrotask(() => {
    Alpine.start()
})
/*
if (window.Livewire) {
    window.Livewire.start();
}
Alpine.start() 
// If you want Alpine's instance to be available everywhere.
*/
//import { Livewire } from '../../../public/vendor/livewire/livewire.js';
//Livewire = require('../../../public/vendor/livewire/livewire.js');
//import Alpine from 'alpinejs' 
//window.Livewire = Livewire;
//window.Livewire.start();
//window.Alpine = Alpine
